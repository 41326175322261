<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmGoal')" data-vv-scope="frmGoal" novalidate="novalidate">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('sidebar.goals') }}</h5>
            <div class="row">
                <custom-input name="goal.year"
                  v-model="goal.year"
                  :label="this.$i18n.t('form.goal.year')"
                  type="text"
                  rootClassName="col-sm-2 form-group mb-4"
                  :max-length="25"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('goal.year')">
                </custom-input>
                <div class="col-sm-3 form-group mb-4">
                  <label>{{ this.$i18n.t('form.goal.idFrequency.label') }}</label>
                  <v-select :disabled="true" name="name" label="name"
                    :clearable="false" :searchable="true" v-model="goal.id_frequency" @search="frequencyFetchOptions"
                    :options="frequencyList" @input="onSelectFrequency" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <!-- Tipo de meta -->
                <div class="col-sm-3 form-group mb-4">
                  <label>{{ this.$i18n.t('form.report.type') }}</label>
                  <v-select :disabled="isEdit"  name="name" label="name"
                    :clearable="false" :searchable="false" v-model="goal.goal_section"
                    :options="goalSectionList" @input="onSelectSection" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row p-0 m-0">
                <div  class="col-12 p-0 m-0">
                    <div class="tier-container w-100">
                      <div class="tier-wrapper border">
                        <div class="tier-goal pb-2 mb-5" v-for="(indicator, indicatorIndex) in goal.goals" :key="indicatorIndex">
                          <div class="tier-title w-100 text-center bg-light border p-1">
                            <p class="tier-title-level mb-0">Nível: {{ indicator.id_indicator.name }}</p>
                          </div>
                          <div class="tier-content">
                            <div class="tier-labels d-flex flex-row">
                              <div class="tier-labels d-inline-flex col-sm-3 p-1 border border-bottom-0" v-for="(periodIndex) in (12 / goal.id_frequency.intervals)" :key="indicatorIndex+'-'+periodIndex">
                                <span class="w-100 text-center">{{ $t('form.goal.idFrequency.' + getFrequencyLabel + '.' + periodIndex) }}</span>
                              </div>
                            </div>
                            <div class="tier-frequency d-flex flex-row">
                              <div class="tier-fields col-sm-3 border pt-1" v-for="(goal, goalIndex) in indicator.frequency_goals" :key="goalIndex">
                                <div class="tier-field form-group d-flex flex-column w-100 p-1 mb-1">
                                  <label for="goal.min_value_period" class="small mb-0 pl-1 bg-light">Mínimo</label>
                                  <custom-input
                                    v-model="goal.min_value_period"
                                    name="goal.min_value_period"
                                    type="text"
                                    rootClassName="form-group no-margin"
                                    inputClassName="md-form-control no-margin"
                                    :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                                    :error="submitted && errors.first('goal.min_value_period')"
                                    :indicator = "indicator"
                                    class="mb-0"
                                    >
                                  </custom-input>
                                </div>
                                <div class="tier-field form-group d-flex flex-column w-100 p-1 mb-1">
                                  <label for="goal.max_value_period" class="small mb-0 pl-1 bg-light">Máximo</label>
                                  <custom-input
                                    v-model="goal.max_value_period"
                                    name="goal.max_value_period"
                                    type="text"
                                    rootClassName="form-group no-margin"
                                    inputClassName="md-form-control no-margin"
                                    :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                                    :error="submitted && errors.first('goal.max_value_period')"
                                    :indicator = "indicator"
                                    class="mb-0"
                                    >
                                  </custom-input>
                                </div>
                                <div class="tier-field form-group d-flex flex-column w-100 p-1 mb-1">
                                  <label for="goal.percent_period" class="small mb-0 pl-1 bg-light">% Comissao</label>
                                  <custom-input
                                    v-model="goal.percent_period"
                                    name="goal.percent_period"
                                    type="text"
                                    rootClassName="form-group no-margin"
                                    inputClassName="md-form-control no-margin"
                                    :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                                    :error="submitted && errors.first('goal.percent_period')"
                                    :indicator = "indicator"
                                    class="mb-0"
                                    >
                                  </custom-input>
                                </div>
                              </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancelGoal">
          Cancelar
        </button>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import GoalService from '@/services/GoalService'
import GoalTierService from '@/services/GoalTierService'

export default {
  name: 'GoalForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.goal-tier.title') + ' - %s'
    }
  },
  data () {
    return {
      percentage: 10,
      isEdit: false,
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      goalList: [],
      frequencyList: [],
      goalSectionList: [
        {
          id: 1,
          name: 'Inteligência Logística'
        },
        {
          id: 2,
          name: 'Vendedor'
        }
      ],
      //  Selected Values (Form)
      goal: {
        year: (new Date()).getFullYear(),
        value: null,
        id_frequency: {
          id: 2,
          description: 'Metas controladas trimestralmente.',
          intervals: 3,
          name: 'Trimestral'
        },
        goal_section: {
          id: 2,
          name: 'Vendedor'
        },
        goals: [
          {
            frequency_goals: [
              {
                percent_period: 0,
                period: 1,
                min_value_period: 0,
                max_value_period: 100000
              },
              {
                percent_period: 0,
                period: 2,
                min_value_period: 0,
                max_value_period: 100000
              },
              {
                percent_period: 0,
                period: 3,
                min_value_period: 0,
                max_value_period: 100000
              },
              {
                percent_period: 0,
                period: 4,
                min_value_period: 0,
                max_value_period: 100000
              }
            ],
            showOnGoal: true,
            id_indicator: {
              description: null,
              id: 39,
              is_percent: null,
              name: 'Mín Trimestre',
              sort_order: 1
            }
          },
          {
            frequency_goals: [
              {
                percent_period: 3,
                period: 1,
                min_value_period: 100000.01,
                max_value_period: 150000
              },
              {
                percent_period: 3,
                period: 2,
                min_value_period: 100000.01,
                max_value_period: 150000
              },
              {
                percent_period: 3,
                period: 3,
                min_value_period: 100000.01,
                max_value_period: 150000
              },
              {
                percent_period: 3,
                period: 4,
                min_value_period: 100000.01,
                max_value_period: 150000
              }
            ],
            showOnGoal: true,
            id_indicator: {
              description: null,
              id: 40,
              is_percent: null,
              name: 'Tier 3 (acima de)',
              sort_order: 1
            }
          },
          {
            frequency_goals: [
              {
                percent_period: 5,
                period: 1,
                min_value_period: 150000.01,
                max_value_period: 210000
              },
              {
                percent_period: 5,
                period: 2,
                min_value_period: 150000.01,
                max_value_period: 210000
              },
              {
                percent_period: 5,
                period: 3,
                min_value_period: 150000.01,
                max_value_period: 210000
              },
              {
                percent_period: 5,
                period: 4,
                min_value_period: 150000.01,
                max_value_period: 210000
              }
            ],
            showOnGoal: true,
            id_indicator: {
              description: null,
              id: 41,
              is_percent: null,
              name: 'Tier 2 (acima de)',
              sort_order: 1
            }
          },
          {
            frequency_goals: [
              {
                percent_period: 8,
                period: 1,
                min_value_period: 210000.01,
                max_value_period: 300000
              },
              {
                percent_period: 8,
                period: 2,
                min_value_period: 210000.01,
                max_value_period: 300000
              },
              {
                percent_period: 8,
                period: 3,
                min_value_period: 210000.01,
                max_value_period: 300000
              },
              {
                percent_period: 8,
                period: 4,
                min_value_period: 210000.01,
                max_value_period: 300000
              }
            ],
            showOnGoal: true,
            id_indicator: {
              description: null,
              id: 42,
              is_percent: null,
              name: 'Tier 1 (acima de)',
              sort_order: 1
            }
          },
          {
            frequency_goals: [
              {
                percent_period: 13,
                period: 1,
                min_value_period: 300000.01,
                max_value_period: null
              },
              {
                percent_period: 13,
                period: 2,
                min_value_period: 300000.01,
                max_value_period: null
              },
              {
                percent_period: 13,
                period: 3,
                min_value_period: 300000.01,
                max_value_period: null
              },
              {
                percent_period: 13,
                period: 4,
                min_value_period: 300000.01,
                max_value_period: null
              }
            ],
            showOnGoal: true,
            id_indicator: {
              description: null,
              id: 43,
              is_percent: null,
              name: 'Tier 0 (acima de)',
              sort_order: 1
            }
          }
        ]
      },
      submitted: false

    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    this.isEdit = this.$route.name === 'GoalEdit'

    if (this.isset(this.$route.params.year)) {
      this.goal.year = this.$route.params.year
    }

    if (this.isset(this.$route.params.section)) {
      this.goal.goal_section = _this.goalSectionList.find(x => x.id === parseInt(this.$route.params.section))
    }

    // Load basilares data
    GoalService.getFrequency().then(res => {
      _this.frequencyList = res.data.data

      // If is edit populate values
      let year = this.goal.year
      let section = this.goal.goal_section.id

      if (year) {
        GoalTierService.getGoalTierByYearAndSection(year, section).then(goals => {
          _this.goal.goal_section = _this.goalSectionList.find(x => x.id === parseInt(goals.data.goalSection))
          _this.goal.year = parseInt(goals.data.year)
          if (this.$router.currentRoute.name === 'GoalTierEdit') {
            _this.goal.goals = goals.data.data
          }
        }).catch(() => {
          _this.isLoading = false
        })
      }
      // End edit
      _this.isLoading = false
    })
  },
  computed: {
    getFrequencyLabel () {
      let intervals = this.goal.id_frequency.intervals
      let type = ''

      if (intervals === 3) {
        type = 'quarter'
      } else if (intervals === 1) {
        type = 'monthly'
      } else if (intervals === 12) {
        type = 'yearly'
      }

      return type
    }
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectFrequency (val) {
      let sections = 12 / val.intervals
      for (let goalIndex in this.goal.goals) {
        // Remove previous settings
        this.goal.goals[goalIndex].frequency_goals = []

        for (let section = 1; section <= sections; section++) {
          let frequencyGoalObject = {}
          frequencyGoalObject.period = section
          frequencyGoalObject.value = 0.0
          frequencyGoalObject.percent = 0.0

          this.goal.goals[goalIndex].frequency_goals.push(frequencyGoalObject)
        }
      }
      this.goal.id_frequency = val
    },
    onSelectSection (val) {
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_goal: object.id } }).href
    },
    /**
     * Triggered when the frequency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    frequencyFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      GoalService.getFrequency(filters).then(res => {
        me.frequencyList = res.data.data
        loading(false)
      })
    },
    onCancelGoal (e) {
      var _this = this
      _this.$router.push({ name: 'GoalTierIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let goalData = {
            year: _this.goal.year,
            id_frequency: _this.goal.id_frequency.id,
            goal_section: _this.goal.goal_section.id,
            goals: _this.goal.goals
          }

          if (this.$router.currentRoute.name === 'GoalTierEdit') {
            goalData['edit'] = 'true'
          }

          GoalTierService.putGoalByYearAndSectionSave(goalData).then(response => {
            _this.$router.push({ name: 'GoalTierIndex' })
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        }
      })
    },
    isset (value) {
      return value != null && value !== 'undefined'
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
